













import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'

@Component
export default class VLoadSpinner extends Vue {
    @Prop({ type: String }) onPage: string
    @Prop({ type: Boolean, default: true }) showText: boolean
}
