



















import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { ProductPageStore } from '@elitepage/store/product-page'
import { ElitepageStore } from '@elitepage/store/elitepage'
import { fetchSingleOrder } from '~/api/order'
import VLoadSpinner from '~/components/VLoadSpinner.vue'
import { BASE_URL } from '~/const/base-url'

@Component({ components: { VLoadSpinner } })
export default class ProductCheckoutSuccessfulPage extends Vue {
    @Prop({ type: String }) producerSlug!: IProducer['slug']
    @Prop({ type: String }) email!: string
    @Prop({ type: Number }) orderId!: number

    BASE_URL = BASE_URL
    isLoading = false
    order: IOrderDataSimple = null

    @ProductPageStore.State('productPage') productPage: IElitepage
    @ElitepageStore.State('producer') producer: IProducer

    @ProductPageStore.Action('loadProductPage') loadProductPage: (producerSlug: IProducer['slug']) => void

    async fetchEmailOrderData() {
        this.isLoading = true
        try {
            this.order = await fetchSingleOrder({ email: this.email, orderId: this.orderId })
        } finally {
            this.isLoading = false
        }
    }

    async mounted() {
        await this.loadProductPage(this.producerSlug)
        this.fetchEmailOrderData()
    }
}
